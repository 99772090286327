import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
}));

const PageHeader = ({ history, ...props }) => {
  const classes = useStyles();
  const { title = "Página sin título", actionButton } = props;

  return (
    <>
      {props.dialog ? (
        <></>
      ) : (
        <>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Grid item xs={12}>
                  {actionButton && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push(actionButton.to);
                      }}
                      style={{
                        float: "right",
                      }}
                      size="small"
                      endIcon={actionButton.icon}
                    >
                      {actionButton.label}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PageHeader;
