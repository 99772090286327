import React from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useModels } from "../hooks/useModels";
import { propertyAccesor } from "../utils/index";

function AsyncAutocompleteSearch({
  label,
  name,
  defaultValue = null,
  labelProp,
  onChange,
  extraParams,
  clearInput,
  error,
  campoError,
  index,
  ignoreIds = [],
  propId = "",
  helperText = "",
  expand = "",
  searchParam = "",
  disabled = false,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [input, setInput] = React.useState("");

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    modelsRefresh,
  ] = useModels({
    name,
    extraParams,
    expand,
  });

  React.useEffect(() => {
    setInput("");
  }, [clearInput]);

  React.useEffect(() => {
    if (defaultValue) {
      setInput(propertyAccesor(defaultValue, labelProp));
    }
  }, [defaultValue, labelProp]);

  const refreshSearch = React.useCallback(
    async (params) => {
      await modelsRefresh(true, params);
    },
    [modelsRefresh]
  );

  const onKeyPressCallback = async (e) => {
    let params = {
      [searchParam !== "" ? searchParam : labelProp]: e.target.value,
    };
    await refreshSearch(params);
  };

  return (
    <Autocomplete
      disabled={disabled}
      id="asynchronous-demo"
      size="small"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={
        ignoreIds.length
          ? models.filter((m) => !ignoreIds.includes(m[propId]))
          : models || []
      }
      onKeyUp={onKeyPressCallback}
      onChange={(e, v) => {
        isNaN(index) ? onChange(e, v) : onChange(index, e, v);
      }}
      defaultValue={defaultValue && defaultValue}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => {
        return propertyAccesor(option, labelProp);
      }}
      loading={modelsLoading}
      clearOnBlur={false}
      inputValue={input}
      onInputCapture={(e) => {
        setInput(e.target.value);
        onChange();
      }}
      onInputChange={(e, r) => setInput(r)}
      noOptionsText="No hay opciones"
      loadingText="Cargando..."
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {modelsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
}

export default AsyncAutocompleteSearch;
