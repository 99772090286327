import React from "react";

import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

const ThemeContext = React.createContext();

export function ThemeProvider(props) {
  const [darkState, setDarkState] = React.useState(false);
  const [textOffset, setTextOffset] = React.useState(0);

  const palletType = darkState ? "dark" : "light";

  let MuiTheme = createMuiTheme(
    {
      palette: {
        type: palletType,
        secondary: {
          main: "#a13800",
        },
        primary: {
          main: "#cd5d23",
        },
      },
      typography: {
        fontSize: 16 + textOffset,
      },
      offset: {
        textOffset,
      },
    },
    esES
  );

  MuiTheme = responsiveFontSizes(MuiTheme);

  const memData = React.useMemo(() => {
    return {
      darkState,
      setDarkState,
      MuiTheme,
      textOffset,
      setTextOffset,
    };
  }, [darkState, setDarkState, MuiTheme, textOffset, setTextOffset]);

  return <ThemeContext.Provider value={memData} {...props} />;
}

export function useTheme() {
  const context = React.useContext(ThemeContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: theme context not defined.";
  }
  return context;
}
