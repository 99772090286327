import React from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

import { useAuth } from "../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mainImage: {
    height: 200,
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [correo, setCorreo] = React.useState("");
  const [clave, setClave] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState([]);
  const [sessionLoading, setSessionLoading] = React.useState(false);

  const { signIn } = useAuth();

  const onSubmitClicked = async (event) => {
    setSessionLoading(true);
    try {
      event.preventDefault();
      await signIn(correo, clave);
    } catch (error) {
      setErrorMessage(error);
    }
    setSessionLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src="iee_logo.png" className={classes.mainImage} alt="" />
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            onChange={(e) => setCorreo(e.target.value)}
            autoFocus
            error={errorMessage === "El Correo no se encontró"}
            helperText={
              errorMessage === "El Correo no se encontró"
                ? "El Correo no se encontró"
                : ""
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setClave(e.target.value)}
            error={errorMessage === "Contraseña incorrecta"}
            helperText={
              errorMessage === "Contraseña incorrecta"
                ? "Contraseña incorrecta"
                : ""
            }
          />
          {sessionLoading ? (
            <Grid container justify="center">
              <CircularProgress className={classes.submit} />
            </Grid>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmitClicked}
            >
              Iniciar sesión
            </Button>
          )}

          <Grid container>
            {false && (
              <Grid item xs>
                <Link href="#" variant="body2">
                  Olvidaste tu contraseña?
                </Link>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body1" color="textSecondary" align="center">
          SISTEMA DE CÓMPUTOS FINALES
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          <strong>v{global.appVersion}</strong>
        </Typography>
      </Box>
    </Container>
  );
}
