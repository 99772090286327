import React from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

import TablePageLayout from "../../components/TablePageLayout";

import { useModels } from "../../hooks/useModels";

// const consejo = {
//   nombre: "NOMBRE CONSEJO",
//   paquetesEsperados: 0,
//   paquetesRecibidos: 0,
//   actasLectura: 0,
//   actasDatosGenerales: 0,
//   actasCotejo: 0,
//   actasRecuento: 0,
//   actasCotejadas: 0,
//   actasRecontadas: 0,
//   estatusComputo: "TERMINADO",
// };

const EstadisticosGlobales = () => {
  const [filtro, setFiltro] = React.useState("");
  const [consejosElectorales, consejosElectoralesLoading] = useModels({
    name: "consejo-electoral/estadistico-global",
    limite: -1,
  });

  const onFiltroChanged = React.useCallback(
    (e) => {
      const { value } = e.target;
      setFiltro(value.toLocaleLowerCase());
    },
    [setFiltro]
  );

  const consejosMemoized = React.useMemo(() => {
    return consejosElectorales?.filter((consejo) =>
      consejo.nombre.toLocaleLowerCase().includes(filtro)
    );
  }, [consejosElectorales, filtro]);

  const downloadClicked = (_) => {
    window.open(
      "https://dev.computosonora.com/v1/excel/estadisticos-global?id=fzIxjAB2ZNXtAkolKFAc2wjfmyd2",
      "_blank"
    );
  };

  return (
    <TablePageLayout
      title="ESTADÍSTICOS GLOBALES DEL PROCESO ELECTORAL"
      links={[
        {
          to: "/estadisticos/global",
          icon: null,
          label: "Estadísticos globales",
        },
      ]}
      tableLoading={consejosElectoralesLoading}
      SearchComponents={
        <>
          <Grid item xs={4}>
            <TextField
              label="Buscador"
              variant="outlined"
              size="small"
              fullWidth
              value={filtro}
              onChange={onFiltroChanged}
            />
          </Grid>
        </>
      }
      // actionButton={{
      //   onClick: downloadClicked,
      //   label: "DESCARGAR REPORTE",
      //   icon: <CloudDownload />,
      // }}
      TableComponent={
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>#</strong>
                </TableCell>
                <TableCell>
                  <strong>CONSEJO</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>PAQUETES TOTALES</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>PAQUETES RECIBIDOS</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ACTAS LEÍDAS</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>DATOS GENERALES</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ACTAS COTEJO</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ACTAS RECUENTO</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ACTAS COTEJADAS</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ACTAS RECONTADAS</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>ESTATUS CÓMPUTO</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {consejosMemoized?.map((consejo, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <strong>{consejo.idConsejoElectoral}</strong>
                  </TableCell>
                  <TableCell>{consejo.nombre}</TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.paquetesEsperados}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(255, 0, 0, 0.3)" }}
                  >
                    {consejo.paquetesRecibidos}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.actasLectura}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.actasDatosGenerales}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 255, 0, 0.3)" }}
                  >
                    {consejo.actasCotejo}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.actasRecuento}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.actasCotejadas}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 0, 0, 0.3)" }}
                  >
                    {consejo.actasRecontadas}
                  </TableCell>
                  <TableCell
                    align="center"
                    // style={{ backgroundColor: "rgb(0, 255, 0, 0.3)" }}
                  >
                    {!parseInt(consejo.actasCotejadas) &&
                    !parseInt(consejo.actasRecontadas)
                      ? "NO INICIADO"
                      : parseInt(consejo.actasRecontadas) +
                          parseInt(consejo.actasCotejadas) ===
                        parseInt(consejo.actasRecuento) +
                          parseInt(consejo.actasCotejo)
                      ? "FINALIZADO"
                      : "EN PROCESO"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default EstadisticosGlobales;
