import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

//solo con produccion
const app = firebase.initializeApp({
  apiKey: "AIzaSyAs1QBlohUHhZm59SMOn4AL3ZC96TbUVLk",
  authDomain: "computo-21.firebaseapp.com",
  databaseURL: "https://computo-21.firebaseio.com",
  projectId: "computo-21",
  storageBucket: "computo-21.appspot.com",
  messagingSenderId: "978720799827",
  appId: "1:978720799827:web:361ab4522956727611daa6",
  measurementId: "G-KKSBB75Y4F",
});

export const auth = app.auth();
export const storage = app.storage();

export default app;

