import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(5),
  },
  text: {
    fontSize: 12 + theme.offset,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography align="center" gutterBottom className={classes.text}>
        Los resultados presentados tienen un carácter informativo y no son
        definitivos, por tanto, no tienen efectos jurídicos. Con base en la Ley
        Federal del Derecho de Autor queda prohibida cualquier modificación al
        diseño de este sitio. La modificación y/o destrucción de la información
        y/o contenido total o parcial de este sitio, es delito federal de
        acuerdo al Código Penal Federal.
      </Typography>
    </footer>
  );
};

export default Footer;
