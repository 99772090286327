import React from "react";
import { auth } from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { useApp } from "./useApp";

const AuthContext = React.createContext();

export function AuthProvider(props) {
  const { setToken } = useApp();
  const [session, sessionLoading, sessionError] = useAuthState(auth);

  const onTokenChange = React.useCallback(
    (userCredential) => {
      console.log("useAuth: [React.useCallback] onTokenChange");
      if (userCredential) {
        userCredential.getIdToken().then((newToken) => {
          setToken(newToken);
        });
      }
    },
    [setToken]
  );

  const signIn = React.useCallback(async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    let mounted = true;
    let unsub = null;
    if (mounted && session) {
      unsub = auth.onIdTokenChanged(onTokenChange);
      // auth.currentUser.getIdTokenResult(true).then(onTokenChange);
    }
    return () => {
      mounted = false;
      if (unsub) unsub();
    };
  }, [session, onTokenChange]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      userError: sessionError,
      signIn,
      signOut,
    };
  }, [session, sessionLoading, sessionError, signIn, signOut]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}
