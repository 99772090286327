import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const override = css``;

const useStyles = makeStyles((theme) => ({
  container: {
    height: 500,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  title: {
    color: "#000",
    fontSize: 20 + theme.offset,
    marginLeft: 36,
    marginBottom: 22,
  },
}));

function FullScreenLoader() {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          Preparando Contenido...
        </Typography>
        <PropagateLoader color="maroon" css={override} size={24} />
      </Grid>
    </Grid>
  );
}

export default FullScreenLoader;
