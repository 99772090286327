import React from "react";

import { CssBaseline, ThemeProvider as Theme } from "@material-ui/core";

import FullScreenLoader from "./components/FullScreenLoader";

import { AppProvider } from "./hooks/useApp";
import { CacheBusterProvider } from "./hooks/CacheBuster";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { AlertProvider } from "./hooks/useAlert";
import { ThemeProvider, useTheme } from "./hooks/useTheme";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";

const App = () => {
  const RootComponent = () => {
    const { MuiTheme } = useTheme();
    const { session, sessionLoading } = useAuth();

    if (sessionLoading) return <FullScreenLoader />;

    return (
      <Theme theme={MuiTheme}>
        <CssBaseline />
        {session ? <PrivateRoutes /> : <PublicRoutes />}
      </Theme>
    );
  };

  return (
    <CacheBusterProvider>
      <AppProvider>
        <AlertProvider>
          <AuthProvider>
            <ThemeProvider>
              <RootComponent />
            </ThemeProvider>
          </AuthProvider>
        </AlertProvider>
      </AppProvider>
    </CacheBusterProvider>
  );
};

export default App;
