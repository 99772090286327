/* CacheBuster component */
import React from "react";
import packageJson from "../../package.json";

import "@firebase/firestore";

global.appVersion = packageJson.version;

const online = window.navigator.onLine;

const CacheBusterContext = React.createContext();

// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split(/\./g);

//   const versionsB = versionB.split(/\./g);
//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());

//     const b = Number(versionsB.shift());
//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }
//   return false;
// };

export function CacheBusterProvider(props) {
  // const reference = app
  //   .firestore()
  //   .collection("generales")
  //   .doc("configuracion");

  // const [isLatestVersion, setIsLatestVersion] = React.useState(false);
  // const [firebaseVersion, firebaseVersionLoading, firebaseVersionError] =
  //   useDocumentData(reference);

  const refreshCacheAndReload = React.useCallback(() => {
    if (caches && online) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    online
      ? window.location.reload()
      : console.log("No se puede actualizar en modo offline");
  }, []);

  // const compareVersions = React.useCallback(async () => {
  //   if (firebaseVersion && !firebaseVersionLoading && !firebaseVersionError) {
  //     // fetch("/meta.json", { cache: "no-cache" })
  //     //   .then((response) => response.json())
  //     //   .then((meta) => {
  //     //     const latestVersion = meta.version;
  //     const latestVersion = firebaseVersion.versionPublica;
  //     const currentVersion = global.appVersion;

  //     const shouldForceRefresh = semverGreaterThan(
  //       latestVersion,
  //       currentVersion
  //     );
  //     if (shouldForceRefresh) {
  //       console.log(
  //         `Nueva versión - ${latestVersion}. Refrescar página con CTRL + R`
  //       );
  //       setIsLatestVersion(false);
  //       setCacheBusterLoading(false);
  //     } else {
  //       console.log(
  //         `Tienes la versión mas reciente - ${latestVersion}. No es necesario refrescar.`
  //       );
  //       setIsLatestVersion(true);
  //       setCacheBusterLoading(false);
  //     }
  //   }
  //   // });
  // }, [firebaseVersion, firebaseVersionLoading, firebaseVersionError]);

  const memData = React.useMemo(() => {
    return { refreshCacheAndReload };
  }, [refreshCacheAndReload]);

  return <CacheBusterContext.Provider value={memData} {...props} />;
}

export function useCacheBuster() {
  const context = React.useContext(CacheBusterContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: cache buster context not defined.";
  }
  return context;
}
