import React from "react";

import { storage } from "../firebase";

const AppContext = React.createContext();

export function AppProvider(props) {
  const [token, setToken] = React.useState(null);
  const [appData, setAppData] = React.useState("");

  React.useEffect(() => {
    if (appData === "") {
      const resultado = storage.ref("publico/appData.json");
      resultado.getDownloadURL().then((url) => {
        fetch(url).then((fetchRes) =>
          fetchRes.json().then((result) => setAppData(result))
        );
      });
    }
  }, [appData]);

  const memData = React.useMemo(() => {
    return {
      token,
      setToken,
      appData,
    };
  }, [token, setToken, appData]);

  return <AppContext.Provider value={memData} {...props} />;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}
