import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import SignIn from "../views/SignIn";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
}));

const PublicRoutes = () => {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </Router>
  );
};

export default PublicRoutes;
