import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import PageHeader from "./PageHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
}));

const PageLayout = ({
  SearchComponents,
  children,
  history,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { title, links, actionButton } = props;

  return (
    <div className={classes.root}>
      <PageHeader history={history} title={title} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            alignItems="flex-end"
            justify="space-between"
          >
            {SearchComponents}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default PageLayout;
