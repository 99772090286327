import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  TextField,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Autocomplete } from "@material-ui/lab";

import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import FormDialog from "../../components/FormDialog";
import OpenField from "../../components/OpenField";

import { useApp } from "../../hooks/useApp";
import { useCacheBuster } from "../../hooks/CacheBuster";
import { useQuery } from "../../hooks/useQuery";
import { Add, AttachFile, Delete, GetApp } from "@material-ui/icons";
import { storage } from "../../firebase";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tabs: {
    padding: theme.spacing(2),
  },
  logoImage: {
    paddingTop: 15,
    width: 120,
    height: 100,
  },
  homeIcon: {
    fontSize: 50,
    paddingTop: 20,
  },
  section: {
    padding: theme.spacing(2),
  },
  sectionContent: { padding: theme.spacing(3), height: "100%" },
  graphCardContent: { padding: theme.spacing(3) },
  cardTitle: {
    fontSize: 22 + theme.offset,
    color: theme.palette.text.primary,
  },
  input: {
    display: "none",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div {...other}>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    display: "none",
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: 24 + theme.offset,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.common.white,
      opacity: 1,
      borderRadius: 5,
      backgroundColor: "gray",
    },
    "&$selected": {
      color: "#fff",
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: 5,
      backgroundColor: "gray",
    },
    "&:focus": {
      color: theme.palette.primary.white,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Home = ({ history }) => {
  const classes = useStyles();
  const query = useQuery();
  const { appData } = useApp();
  const { session } = useAuth();
  const { refreshCacheAndReload } = useCacheBuster();

  const isAdmin = session.uid === "fzIxjAB2ZNXtAkolKFAc2wjfmyd2";
  const tipoConsejo = query.get("tipo") || 0;

  const [formDialogOpen, setFormDialogOpen] = React.useState(false);

  const [value, setValue] = React.useState(parseInt(tipoConsejo));
  const [nombreReporte, setNombreReporte] = React.useState("");
  const [reporte, setReporte] = React.useState(null);
  const [reportes, setReportes] = React.useState([]);
  const [refreshList, setRefreshList] = React.useState(false);

  const fecha = new Date(appData.fecha).toLocaleString("es-MX");

  const dataEstatal = appData?.gubernatura[0];

  const dataDistrital = appData.distrital.sort(
    (a, b) =>
      parseInt(a.consejo.nombre.split(" ")[2]) -
      parseInt(b.consejo.nombre.split(" ")[2])
  );

  const dataMunicipal = appData.municipal.sort((a, b) =>
    a.consejo.nombre.toUpperCase().split(" ")[2] <
    b.consejo.nombre.toUpperCase().split(" ")[2]
      ? -1
      : 1
  );

  React.useEffect(() => {
    const listRef = storage.ref(
      `publico/reportes/${dataEstatal.consejo.idConsejoElectoral}`
    );

    listRef
      .listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.getMetadata().then((data) => {
            let tmpData = data;
            storage
              .ref(tmpData.fullPath)
              .getDownloadURL()
              .then((res) => (tmpData.downloadURL = res))
              .then((_) => setReportes((r) => [...r, tmpData]));
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataEstatal, refreshList]);

  const onRefreshPressed = async () => {
    try {
      await refreshCacheAndReload();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileRead = async (event) => {
    const files = event.target.files;
    const lastFile = files[files.length - 1];
    setReporte(lastFile);
  };

  const refreshReportes = () => {
    setReportes([]);
    setRefreshList((r) => !r);
  };

  const onSavePressed = () => {
    if (reporte) {
      const extension = reporte?.name.split(".")[1];
      storage
        .ref(
          `publico/reportes/${dataEstatal.consejo.idConsejoElectoral}/${nombreReporte}.${extension}`
        )
        .put(reporte)
        .then((res) => {
          setFormDialogOpen(false);
          setNombreReporte("");
          setReporte(null);
          refreshReportes();
        });
    }
  };

  const onDeletePressed = (path) => {
    if (path) {
      const ref = storage.ref(path);
      ref
        .delete()
        .then((res) => refreshReportes())
        .catch((e) => console.log(e));
    }
  };

  const downloadFile = (filePath) => {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  };

  let rows = [
    {
      prop: "name",
      name: "Archivo",
      cell: (row) => <>{row.name}</>,
    },
    {
      prop: "timeCreated",
      name: "Fecha de Creación",
      cell: (row) => <>{row.timeCreated}</>,
    },
    {
      prop: "downloadURL",
      name: "Descargar Reporte",
      cell: (row) => (
        <>
          <GetApp
            style={{
              cursor: "pointer",
            }}
            onClick={() => downloadFile(row.downloadURL)}
          />
        </>
      ),
    },
  ];

  if (isAdmin) {
    rows = [
      ...rows,
      {
        prop: "acciones",
        name: "Borrar Reporte",
        cell: (row) => (
          <>
            {isAdmin ? (
              <Delete
                style={{ cursor: "pointer" }}
                onClick={() => onDeletePressed(row.fullPath)}
              />
            ) : null}
          </>
        ),
      },
    ];
  }

  const FirstTab = () => {
    return (
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item md={8} xs={6} className={classes.section}>
            <Grid container component={Card} className={classes.sectionContent}>
              <Grid item xs={12}>
                <Typography className={classes.cardTitle}>
                  <strong>Último Corte:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.cardTitle}>{fecha}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={6} className={classes.section}>
            <Grid container component={Card} className={classes.sectionContent}>
              <Button onClick={onRefreshPressed}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.cardTitle}>
                      <RefreshIcon />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center">ACTUALIZAR</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.section}>
            <Grid container component={Card} className={classes.sectionContent}>
              <Grid item xs={12}>
                <TablePageLayout
                  title={`REPORTES - ${dataEstatal.consejo.nombre}`}
                  SearchComponents={
                    isAdmin ? (
                      <Grid item xs={12}>
                        <Button
                          startIcon={<Add />}
                          size="small"
                          variant="contained"
                          color="primary"
                          style={{ float: "right" }}
                          onClick={() => {
                            setFormDialogOpen(true);
                          }}
                        >
                          Agregar reporte
                        </Button>
                      </Grid>
                    ) : null
                  }
                  TableComponent={
                    <TablePagination
                      header={[]}
                      data={reportes ? reportes : []}
                      extraRows={rows}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const SecondTab = () => {
    return (
      <TabPanel value={value} index={1}>
        <Grid container>
          <Grid item md={8} xs={10} style={{ padding: 20 }}>
            <Autocomplete
              id="Distrital"
              fullWidth
              options={dataDistrital}
              onChange={(e, v) =>
                history.push(
                  `/detalle?id=${v.consejo.idConsejoElectoral}&tipo=${value}`
                )
              }
              getOptionLabel={(option) => option.consejo.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione el consejo distrital"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  const ThirdTab = () => {
    return (
      <TabPanel value={value} index={2}>
        <Grid container>
          <Grid item md={8} xs={10} style={{ padding: 20 }}>
            <Autocomplete
              id="Municipal"
              fullWidth
              options={dataMunicipal}
              onChange={(e, v) =>
                history.push(
                  `/detalle?id=${v.consejo.idConsejoElectoral}&tipo=${value}`
                )
              }
              getOptionLabel={(option) => option.consejo.nombre}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione el consejo municipal"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      </TabPanel>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={1}>
              <Button fullWidth onClick={() => setValue(0)}>
                <Typography>
                  <HomeIcon className={classes.homeIcon} />
                </Typography>
              </Button>
            </Grid>
            <Grid item md={8} xs={11}>
              <AntTabs
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                <AntTab label="Gubernatura" {...a11yProps(0)} />
                <AntTab label="Diputaciones" {...a11yProps(1)} />
                <AntTab label="Ayuntamientos" {...a11yProps(2)} />
              </AntTabs>
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container justify="center">
                <img
                  src="iee_logo.png"
                  alt="Logo"
                  className={classes.logoImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FirstTab />
            </Grid>
            <Grid item xs={12}>
              <SecondTab />
            </Grid>
            <Grid item xs={12}>
              <ThirdTab />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormDialog
        title="AGREGAR NUEVO REPORTE"
        open={formDialogOpen}
        setOpen={setFormDialogOpen}
        maxWidth="lg"
        formContent={
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OpenField
                label="Agregue el nombre del reporte"
                onChange={(e) => setNombreReporte(e.target.value)}
                value={nombreReporte}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                id="icon-button-file"
                name="cameraInput"
                className={classes.input}
                onChange={handleFileRead}
              />
              <label htmlFor="icon-button-file" style={{ cursor: "pointer" }}>
                <Grid container>
                  <Grid item sm={1} xs={2}>
                    <AttachFile style={{ fontSize: 50 }} />
                  </Grid>
                  <Grid item sm={11} xs={10}>
                    <Typography align="left" style={{ paddingTop: 6 }}>
                      {reporte
                        ? reporte.name
                        : "Presiona para agregar un archivo para el reporte"}
                    </Typography>
                  </Grid>
                </Grid>
              </label>
            </Grid>
          </Grid>
        }
        formActions={
          <Button variant="outlined" color="primary" onClick={onSavePressed}>
            GUARDAR REPORTE
          </Button>
        }
      />
    </div>
  );
};

export default Home;
