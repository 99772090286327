import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Grid,
  Switch,
  Tooltip,
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import { ChevronLeft, ExitToApp, Inbox, Mail, Menu } from "@material-ui/icons";

import { useTheme } from "../hooks/useTheme";
import { useAuth } from "../hooks/useAuth";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  image: {
    width: 100,
  },
  paper: {
    width: 300,
    background: "#b5b5b5",
  },
  icon: { color: "#fff", fontSize: 22 },
  appBar: {
    height: 75,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  signOut: {
    marginLeft: 14,
    cursor: "pointer",
    color: "#fff",
    fontSize: 22,
  },
  menuIcon: { color: "#fff", marginRight: 10 },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    height: 75,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: theme.palette.primary.main,
  },
  navlink: { textDecoration: "none", color: "#000" },
}));

function Header() {
  const classes = useStyles();
  const { darkState, setDarkState, setTextOffset } = useTheme();
  const { signOut } = useAuth();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const homeLogo = (
    <NavLink to="/">
      <img src="logo_iee_full.png" alt="logo" className={classes.image} />
    </NavLink>
  );

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolbar}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <IconButton onClick={handleDrawerOpen} edge="start">
            <Menu className={classes.menuIcon} />
          </IconButton>
          {homeLogo}
        </Grid>
        {/* <Tooltip
          placement="top"
          title="Tamaño de texto"
          arrow
          children={
            <>
              <Button
                onClick={() => {
                  setTextOffset((o) => o - 1);
                }}
              >
                -
              </Button>
              <SortByAlphaIcon className={classes.icon} />
              <Button
                onClick={() => {
                  setTextOffset((o) => o + 1);
                }}
              >
                +
              </Button>
            </>
          }
        />
        <Tooltip
          placement="bottom"
          title="Prender/apagar modo oscuro"
          arrow
          children={
            <Switch
              onClick={() => setDarkState((s) => !s)}
              checked={darkState}
              icon={<WbSunnyIcon className={classes.icon} />}
              checkedIcon={<NightsStayIcon className={classes.icon} />}
            />
          }
        /> */}
        <Tooltip
          placement="bottom"
          title="Cerrar sesión"
          arrow
          children={<ExitToApp onClick={signOut} className={classes.signOut} />}
        />
      </Toolbar>
    );
  };

  return (
    <>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        position="static"
      >
        {displayDesktop()}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            { name: "Conteo Preliminar", to: "/" },
            { name: "Estadísticos Globales", to: "/estadisticos-globales" },
          ].map((element, index) => (
            <>
              <NavLink to={element.to} className={classes.navlink}>
                <ListItem button key={element.name}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <Inbox /> : <Mail />}
                  </ListItemIcon>
                  <ListItemText primary={element.name} />
                </ListItem>
              </NavLink>
              <Divider />
            </>
          ))}
        </List>
      </Drawer>
    </>
  );
}

export default Header;
