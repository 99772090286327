import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import Home from "../views/home";
import PaquetesDetalle from "../views/home/detail";

import Header from "../components/Header";
import Footer from "../components/Footer";
import FullScreenLoader from "../components/FullScreenLoader";

import { useApp } from "../hooks/useApp";
import EstadisticosGlobales from "../views/informes/estadisticos";
import ConteoPreliminar from "../views/informes/conteo";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
}));

const PrivateRoutes = () => {
  const classes = useStyles();
  const { appData } = useApp();

  if (!appData) return <FullScreenLoader />;

  return (
    <Router>
      <div className={classes.root}>
        <Header />
        <Switch>
          {/* <Route exact path="/" component={Home} />
          <Route exact path="/detalle" component={PaquetesDetalle} /> */}
          <Route exact path="/" component={ConteoPreliminar} />
          <Route
            exact
            path="/estadisticos-globales"
            component={EstadisticosGlobales}
          />

          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default PrivateRoutes;
